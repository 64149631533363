<template>
  <v-dialog v-model="visible" persistent max-width="1200">
    <v-card class="pa-5 rounded-lg" id="card-custom">
      <v-btn @click="visible = false" x-small icon class="float-right">
        <v-icon>close</v-icon>
      </v-btn>
      <v-card-text class="subtitle-2 pt-0 font-weight-bold">
        Mutasi Jabatan dan Unit Kerja Antar 2 Pegawai
      </v-card-text>
      <v-divider class="mb-5" />
      <v-form
        id="form"
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="save()"
      >
        <v-row>
          <v-col cols="5">
            <p class="caption mb-0 text-center">Pegawai 1</p>
          </v-col>
          <v-col cols="2">
            <v-card-actions>
              <v-spacer />
              <v-btn small text class="text-capitalize" @click="handleReset"
                >&lt; Reset &gt;</v-btn
              >
              <v-spacer />
            </v-card-actions>
          </v-col>
          <v-col cols="5">
            <p class="caption mb-0 text-center">Pegawai 2</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col cols="3" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >NIP</v-subheader
                >
              </v-col>
              <v-col cols="9" class="py-1">
                <v-text-field
                  v-model="detail.NIP"
                  hide-details
                  disabled
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Nama</v-subheader
                >
              </v-col>
              <v-col cols="9" class="py-1">
                <v-text-field
                  v-model="detail.nama"
                  hide-details
                  disabled
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-card outlined class="pa-5 mt-2">
              <p class="caption pt-0 font-weight-bold">Unit Kerja Pegawai</p>
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-autocomplete
                    :items="unitutamaList"
                    :loading="loading_unitutama"
                    outlined
                    dense
                    disabled
                    hide-details
                    v-model="detail.unit_utama"
                    item-text="singkatan"
                    item-value="unit_utama_id"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-autocomplete
                    :items="unit2List"
                    :loading="loadingUnit"
                    outlined
                    dense
                    disabled
                    hide-details
                    v-model="detail.unit_kerja_2"
                    item-text="singkatan"
                    item-value="unit_kerja_2_id"
                    @change="changeUnit(2)"
                    @click:clear="changeUnit(2)"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-autocomplete
                    :items="unit3List"
                    :loading="loadingUnit"
                    outlined
                    dense
                    disabled
                    hide-details
                    v-model="detail.unit_kerja_3"
                    item-text="singkatan"
                    item-value="unit_kerja_3_id"
                    @change="changeUnit(3)"
                    @click:clear="changeUnit(3)"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-autocomplete
                    :items="unit4List"
                    :loading="loadingUnit"
                    outlined
                    dense
                    disabled
                    hide-details
                    v-model="detail.unit_kerja_4"
                    item-text="singkatan"
                    item-value="unit_kerja_4_id"
                    @change="changeUnit(4)"
                    @click:clear="changeUnit(4)"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <p class="caption pt-0 font-weight-bold">Jabatan Pegawai</p>
              <v-row>
                <v-col cols="2" class="py-1">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >Jabatan</v-subheader
                  >
                </v-col>
                <v-col cols="10" class="py-1">
                  <v-autocomplete
                    :items="jabatanList"
                    :loading="loading_jabatan"
                    outlined
                    dense
                    disabled
                    hide-details
                    v-model="detail.jabatan"
                    item-text="nama_jabatan"
                    item-value="jabatan_id"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="py-1">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >Eselon</v-subheader
                  >
                </v-col>
                <v-col cols="4" class="py-1">
                  <v-autocomplete
                    :items="eselonList"
                    :loading="loading_eselon"
                    outlined
                    dense
                    disabled
                    hide-details
                    v-model="detail.eselon"
                    item-text="keterangan"
                    item-value="eselon_id"
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2" class="py-1">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >Kelas (Grade)</v-subheader
                  >
                </v-col>
                <v-col cols="4" class="py-1">
                  <v-autocomplete
                    :items="kelasList"
                    :loading="loading_kelas"
                    outlined
                    dense
                    disabled
                    hide-details
                    v-model="detail.kelas"
                    item-text="kelas_jabatan"
                    item-value="kelas_jabatan"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <p class="caption ma-0 text-right">Kuota Jabatan: 0 Pegawai</p>
              <p class="caption ma-0 text-right">Telah Digunakan: 0 Pegawai</p>
              <p class="caption mt-2 font-weight-bold">TMT & SK</p>
              <v-row>
                <v-col cols="2" class="py-1">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >TMT</v-subheader
                  >
                </v-col>
                <v-col cols="10" class="py-1">
                  <v-text-field
                    class="mt-1"
                    v-model="detail.tgl_mulai_PNS"
                    type="date"
                    outlined
                    dense
                    disabled
                    hide-details
                    :rules="[v => !!v || 'TMT harus diisi']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="py-1">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >No SK</v-subheader
                  >
                </v-col>
                <v-col cols="10" class="py-1">
                  <v-text-field
                    class="mt-1"
                    v-model="detail.no_sk_PNS"
                    outlined
                    dense
                    disabled
                    hide-details
                    :rules="[v => !!v || 'Nomor SK harus diisi']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="py-1">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >Tgl SK</v-subheader
                  >
                </v-col>
                <v-col cols="10" class="py-1">
                  <v-text-field
                    class="mt-1"
                    v-model="detail.tgl_sk_PNS"
                    type="date"
                    outlined
                    dense
                    disabled
                    hide-details
                    :rules="[v => !!v || 'Tanggal SK harus diisi']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-row>
              <v-col cols="3" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >NIP</v-subheader
                >
              </v-col>
              <v-col cols="9" class="py-1">
                <v-text-field
                  v-model="form.NIP"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Nama</v-subheader
                >
              </v-col>
              <v-col cols="9" class="py-1">
                <v-text-field
                  v-model="form.nama"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-card outlined class="pa-5 mt-2">
              <p class="caption pt-0 font-weight-bold">Unit Kerja Pegawai</p>
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-autocomplete
                    :items="unitutamaList"
                    :loading="loading_unitutama"
                    outlined
                    dense
                    disabled
                    hide-details
                    v-model="form.unit_utama"
                    item-text="singkatan"
                    item-value="unit_utama_id"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-autocomplete
                    :items="unit2List"
                    :loading="loadingUnit"
                    outlined
                    dense
                    disabled
                    hide-details
                    v-model="form.unit_kerja_2"
                    item-text="singkatan"
                    item-value="unit_kerja_2_id"
                    @change="changeUnit(2)"
                    @click:clear="changeUnit(2)"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-autocomplete
                    :items="unit3List"
                    :loading="loadingUnit"
                    outlined
                    dense
                    disabled
                    hide-details
                    v-model="form.unit_kerja_3"
                    item-text="singkatan"
                    item-value="unit_kerja_3_id"
                    @change="changeUnit(3)"
                    @click:clear="changeUnit(3)"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-autocomplete
                    :items="unit4List"
                    :loading="loadingUnit"
                    outlined
                    dense
                    disabled
                    hide-details
                    v-model="form.unit_kerja_4"
                    item-text="singkatan"
                    item-value="unit_kerja_4_id"
                    @change="changeUnit(4)"
                    @click:clear="changeUnit(4)"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <p class="caption pt-0 font-weight-bold">Jabatan Pegawai</p>
              <v-row>
                <v-col cols="2" class="py-1">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >Jabatan</v-subheader
                  >
                </v-col>
                <v-col cols="10" class="py-1">
                  <v-autocomplete
                    :items="jabatanList"
                    :loading="loading_jabatan"
                    outlined
                    dense
                    disabled
                    hide-details
                    v-model="form.jabatan"
                    item-text="nama_jabatan"
                    item-value="jabatan_id"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="py-1">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >Eselon</v-subheader
                  >
                </v-col>
                <v-col cols="4" class="py-1">
                  <v-autocomplete
                    :items="eselonList"
                    :loading="loading_eselon"
                    outlined
                    dense
                    disabled
                    hide-details
                    v-model="form.eselon"
                    item-text="keterangan"
                    item-value="eselon_id"
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2" class="py-1">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >Kelas (Grade)</v-subheader
                  >
                </v-col>
                <v-col cols="4" class="py-1">
                  <v-autocomplete
                    :items="kelasList"
                    :loading="loading_kelas"
                    outlined
                    dense
                    disabled
                    hide-details
                    v-model="form.kelas"
                    item-text="kelas_jabatan"
                    item-value="kelas_jabatan"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <p class="caption ma-0 text-right">Kuota Jabatan: 0 Pegawai</p>
              <p class="caption ma-0 text-right">Telah Digunakan: 0 Pegawai</p>
              <p class="caption mt-2 font-weight-bold">TMT & SK</p>
              <v-row>
                <v-col cols="2" class="py-1">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >TMT</v-subheader
                  >
                </v-col>
                <v-col cols="10" class="py-1">
                  <v-text-field
                    class="mt-1"
                    v-model="form.tgl_mulai_PNS"
                    type="date"
                    outlined
                    dense
                    disabled
                    hide-details
                    :rules="[v => !!v || 'TMT harus diisi']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="py-1">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >No SK</v-subheader
                  >
                </v-col>
                <v-col cols="10" class="py-1">
                  <v-text-field
                    class="mt-1"
                    v-model="form.no_sk_PNS"
                    outlined
                    dense
                    disabled
                    hide-details
                    :rules="[v => !!v || 'Nomor SK harus diisi']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="py-1">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >Tgl SK</v-subheader
                  >
                </v-col>
                <v-col cols="10" class="py-1">
                  <v-text-field
                    class="mt-1"
                    v-model="form.tgl_sk_PNS"
                    type="date"
                    outlined
                    dense
                    disabled
                    hide-details
                    :rules="[v => !!v || 'Tanggal SK harus diisi']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            small
            outlined
            color="#717171"
            class="px-12"
            @click="visible = false"
            >Batal</v-btn
          >
          <v-btn
            small
            color="#FBB005"
            class="px-12 subtitle-2 text-capitalize"
            :loading="formLoading"
            type="submit"
            :disabled="!valid"
            form="form"
            >Simpan</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import PublicService from "@/services/resources/public.service";

export default {
  data() {
    return {
      valid: false,
      visible: false,
      formLoading: false,
      loading_jabatan: false,
      loading_eselon: false,
      loading_kelas: false,
      loading_unitutama: false,
      loadingUnit: false,
      unitutamaList: [],
      jabatanList: [],
      eselonList: [],
      kelasList: [],
      unit2List: [],
      unit3List: [],
      unit4List: [],
      detail: {
        NIP: null,
        nama: null,
        unit_utama: null,
        unit_kerja_2: null,
        unit_kerja_3: null,
        unit_kerja_4: null,
        jabatan: null,
        eselon: null,
        kelas: null,
        tgl_mulai_PNS: null,
        no_sk_PNS: null,
        tgl_sk_PNS: null
      },
      form: {
        NIP: null,
        nama: null,
        unit_utama: null,
        unit_kerja_2: null,
        unit_kerja_3: null,
        unit_kerja_4: null,
        jabatan: null,
        eselon: null,
        kelas: null,
        tgl_mulai_PNS: null,
        no_sk_PNS: null,
        tgl_sk_PNS: null
      }
    };
  },
  methods: {
    change(val) {
      this.visible = val;
    },
    getCurrentUnit() {
      let unit_kerja = {
        unit_utama: this.detail.unit_utama,
        unit_kerja_2: this.detail.unit_kerja_2,
        unit_kerja_3: this.detail.unit_kerja_3,
        unit_kerja_4: this.detail.unit_kerja_4
      };
      this.form = this.$_.merge(this.form, unit_kerja);
      this.populateUnit();
    },
    changeUnit(type = 2) {
      if (type == 2) {
        this.unit3List = this.unit2List.find(
          d => d.unit_kerja_2_id == this.form.unit_kerja_2?.unit_kerja_2_id
        )?.unit_kerja3;
        this.form.unit_kerja_3 = null;
        this.form.unit_kerja_4 = null;
      } else if (type == 3) {
        this.unit4List = this.unit3List.find(
          d => d.unit_kerja_3_id == this.form.unit_kerja_3?.unit_kerja_3_id
        )?.unit_kerja4;
        this.form.unit_kerja_4 = null;
      }
    },
    populateUnit() {
      this.unit3List = this.unit2List.find(
        d => d.unit_kerja_2_id == this.form.unit_kerja_2?.unit_kerja_2_id
      )?.unit_kerja3;
      this.unit4List = this.unit3List.find(
        d => d.unit_kerja_3_id == this.form.unit_kerja_3?.unit_kerja_3_id
      )?.unit_kerja4;
    },
    populate(item) {
      this.detail = this.$_.merge(this.detail, item);
      this.visible = true;
    },
    handleReset() {
      Object.keys(this.form).forEach(key => {
        this.form[key] = null;
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        console.log(this.form);
      }
    },
    // Service
    async getLov(type) {
      try {
        this[`loading_${type}`] = true;
        await PublicService[`${type}GetList`]({
          search: ""
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this[`${type}List`] = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this[`loading_${type}`] = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await PublicService.getUnitKerjaList()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.unit2List = data;
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getLov("jabatan");
    this.getLov("eselon");
    this.getLov("kelas");
    this.getLov("unitutama");
    this.getListUnit();
  }
};
</script>
